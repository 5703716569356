@media(min-width:1800px) {
    .sm-title {
        font-size: 17px;
    }
    .value-text {
        font-size: 20px;
        margin-top: 15px;
    }
    .cs-card {
        padding: 20px 15px 25px;
    }
}
@media(max-width:1500px) {
    .cpcs-cards {
        margin: 0 -1rem;
    }
    .cpcs-cards .col-md-4 {
        padding: 1.4rem 0.5rem;
    }
    .cpcs-cards .sm-title {
        font-size: 12px;
    }
    .value-text {
        margin-top: 14px;
        font-size: 15px;
    }
    .custom-table table thead tr th {
        font-size: 13px;
    }
    .custom-table table tbody tr td {
        font-size: 13px;
    }
    .custom-table table tbody tr td svg {
        width: 18px;
        height: 18px;
    }
    .cp-mxw {
        max-width: 162px;
    }
}
@media(max-width:1400px) {
    .main-card .col-md-3 {
        width: 30%;
    }
    .main-card .col-md-9 {
        width: 70%;
    }
    .main-card .md-title {
        font-size: 20px;
    }
}
@media (max-width:1366px) {
    // .custom-menu-block nav {
    //     width: calc(100% - 50px);
    // }
    // .custom-menu-block nav ul {
    //     overflow-x: auto;
    // }
    // .custom-menu-block nav li button {
    //     white-space: nowrap;
    // }
}
@media (max-width:991px) {
    .cpcs-cards {
        margin-top: 20px;
    }
    .cpcs-cards .col-md-4 {
        padding: 0.5rem 1rem;
    }
    .main-card {
        padding: 25px;
    }
    .sm-nowrap {
        white-space: nowrap;
    }
}
@media (max-width:899px) {
    .drawer-hide {
        position: absolute;
        right: inherit;
        top: inherit;
    }
}
@media (max-width:767px) {
    .filter-block {
        flex-wrap: wrap;
    }
    .filter-block>div:first-child {
        max-width: calc(100% - 140px);
        flex-basis: calc(100% - 140px);
    }
    .filter-block>div:last-child {
        max-width: 100%;
        flex-basis: 100%;
        justify-content: flex-start;
    }
    .cs-modal {
        width: calc(100% - 30px);
    }
    .cs-modal .MuiPaper-root {
        max-height: 90vh;
        overflow-y: auto;
    }
    .cs-modal .MuiPaper-root form {
        max-height: 84vh;
        margin-right: 0 !important;
        padding-right: 10px;
        overflow-y: auto;
    }
    .cs-close {
        z-index: 1;
    }
    
    .inv-left {
        max-width: 100%;
        flex-basis: 100%;
    }
    .inv-right {
        max-width: 100%;
        flex-basis: 100%;
    }
}
@media (max-width:599px) {
    .search-btn button,
    .xs-btn-width button {
        width: 100%;
        text-align: center;
        display: block !important;
        padding: 0.7rem 0.25rem;
        min-width: inherit;
    }
}
@media (max-width:575px) {
    .xs-text-center {
        text-align: center;
        justify-content: center;
    }
    .breadcrumb-flex-wrap {
        flex-wrap: wrap;
    }
    .breadcrumb-box {
        margin-top: 5px;
        flex-wrap: wrap;
    }
    .table-bottom-block {
        flex-wrap: wrap;
        column-gap: 0;
    }
    .table-bottom-block p {
        width: 50%;
    }
    .delete-transaction-modal {
        width: calc(100% - 30px);
    }
}


