// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
.css-13xc10z-MuiButtonBase-root-MuiTab-root {
    width: 700px !important;
    margin: 2px;
    background-color: #4050b5 !important;
}
.css-1ujnqem-MuiTabs-root {
    margin: auto !important;
}

select {
    width: 205px !important;
    padding: 14px !important;
    border-radius: 6px !important;
}

.input input {
    width: 252px;
    padding: 16px !important;
    border-radius: 9px !important;
    margin: 1px;
}
.margin-bottom {
    margin-bottom: 1px;
}
.margin-personalize {
    text-align: center;
    border: 2px solid black;
    background-color: grey;
    color: #000;
    padding: 5px;
}
.boldText {
    font-weight: 700;
    display: inline-block;
    width: 15%;
}
li {
    list-style-type: none;
}
.options {
    text-align: center;
    display: inline-block;
    width: 70%;
}
.list-options {
    border: 1px solid gray;
    width: 95%;
    background-color: #fff;
}
.edit-button {
    display: inline-block;
    margin: 3px;
}
.active-edit {
    display: inline-block;
    margin: 1px;
}
.cs-card {
    position: relative;
    padding: 13px 5px 18px 15px;
    border-radius: 13px;
    border: 1.30814px solid #eee9fc;
    box-shadow: 0px 4.70932px 11.1595px rgba(0, 0, 0, 0.05);
    border-radius: 12.9506px;
}
.cs-card::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 13px;
    background: #0000ff;
    border-radius: 13px 0 0 13px;
}
.sm-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #948fb5;
}
.text-center {
    text-align: center !important;
}
.value-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    text-transform: capitalize;
    color: #333333;
    margin-top: 20px;
}
.main-card {
    padding: 20px 40px 40px 25px;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
}
.md-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 22.5641px;
    line-height: 34px;
    color: #333333;
}
.mt-3 {
    margin-top: 1rem !important;
}
.d-flex {
    display: flex;
}
.pl-2 {
    padding-left: 2rem !important;
}
.w-10 {
    max-width: 5% !important;
}
.cp-mxw {
    max-width: 174px;
    margin: 0 auto;
    padding-top: 1.4rem;
}
.info-box {
    position: relative;
    margin-left: 8px;
    display: inline-block;
}
.info-box .iconinfo {
    vertical-align: middle;
    cursor: pointer;
}
.info-pos {
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translate(0, -50%);
    background: #fff;
    border-radius: 4px;
    box-shadow: -3px 0 15px rgb(0 0 0 / 15%);
    padding: 15px;
    font-size: 10px;
    line-height: normal;
    text-align: center;
    width: 170px;
    display: none;
}
.info-box:hover .info-pos {
    display: block;
}
.info-pos:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 50%;
    margin-top: -10px;
    border-right: solid 10px #fff;
    border-top: solid 10px transparent;
    border-bottom: solid 10px transparent;
}
.cpcs-cards {
    margin: 0 -2rem;
}
.cpcs-cards .col-md-4 {
    padding: 1.4rem 0.5rem;
}
.cp-mx-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 250px;
    margin: 0 auto;
}
.cp-mx-list li {
    position: relative;
    width: 45%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #333333;
}
.cp-mx-list li span {
    font-weight: 800;
}
.cp-mx-list li:first-child {
    width: 55%;
}
.cp-mx-list li:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 6px;
    background: #ffd18c;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.cp-mx-list li.green:before {
    background: #96ca6d;
}
.custom-table table {
    caption-side: bottom;
    border-collapse: collapse;
}
.custom-table table thead tr th {
    background: #0000ff;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 15.3919px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #ffffff;
    padding: 13px 16px 13px 16px !important;
    text-align: left;
    line-height: 1.5rem;
    border: none;
}
// .custom-table table thead tr th:first-child {
//     border-radius: 5.71484px 0px 0px 0px;
// }
// .custom-table table thead tr th:last-child {
//     border-radius: 0px 5.71484px 0px 0px;
// }
.custom-table table tbody tr td {
    background: #fff;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15.3919px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #616161;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
}
.custom-table table tbody tr:nth-of-type(odd) td {
    background-color: #f3f4fa;
}
.custom-table table tbody tr td:first-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
}
.custom-table table tbody tr td:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
}
.custom-table table tbody tr:last-child td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.text-success {
    color: #4caf50 !important;
}
.text-danger {
    color: #d32f2f !important;
}
.text-theme {
    color: #0000ff !important;
}
.rupees-sign {
    color: #948fb5 !important;
}
.main-logo img {
    height: 40px;
    max-width: 100%;
}
.custom-card {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}
.mnh-50 {
    min-height: 50%;
}
.mnh-100 {
    min-height: 100%;
}
.custom-card .custom-card {
    box-shadow: none;
}
.custom-card .v-label {
    font-size: 13px;
    margin-bottom: 3px;
}
.custom-card .v-value {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    word-break: break-word;
}
.custom-card .v-value a {
    word-break: break-word;
    font-weight: 600;
    color: #616161;
    text-decoration: none;
}
.xs-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
}
.pdficon {
    background: url(../images/pdf.svg) no-repeat center center;
    background-size: 100%;
    width: 17px;
    min-width: 17px;
    height: 17px;
    display: inline-block;
    margin-right: 5px;
}
.custom-card .card-header {
    padding: 16px;
    background: transparent;
    border-bottom-color: #eeeeee;
    font-size: 0.875rem;
    font-weight: 500;
    color: #212121;
    line-height: 1.75;
    border-radius: 8px 8px 0 0;
}

.custom-upload {
    position: relative;
    overflow: hidden;
}

.custom-upload .custom-upload-btn {
    background: rgba(0, 0, 255, 0.1);
    border: 1px solid rgb(40 53 147 / 20%);
    border-radius: 4px;
    color: #0000ff;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1.3;
    padding: 0.7rem 1.25rem;
    width: 100%;
}

.custom-upload input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}
.theme-btn {
    background-color: rgb(40, 53, 147);
    border: 1px solid rgb(40, 53, 147);
    height: 42px;
    color: #fff;
}
.theme-btn:hover {
    background-color: rgb(40, 53, 147);
    border: 1px solid rgb(40, 53, 147);
    color: #fff;
}
.btn-success {
    background: #4caf50;
    border: 1px solid #c3bfbf;
    height: 42px;
    color: #fff;
}
.mnw-150 {
    min-width: 150px;
}
.custom-nav-tabs {
    display: flex;
    padding: 0;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}
.custom-nav-tabs.nav-tabs .nav-link {
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    flex-shrink: 0;
    padding: 12px 16px;
    color: #9e9e9e;
    cursor: pointer;
    border-bottom: solid 2px transparent;
}
.custom-nav-tabs.nav-tabs .nav-link,
.custom-nav-tabs.nav-tabs .nav-link.active,
.custom-nav-tabs.nav-tabs .nav-item.show .nav-link {
    border: none;
    border-bottom: solid 2px transparent;
}
.custom-nav-tabs.nav-tabs .nav-link.active {
    color: #4956a8;
    margin-bottom: 0;
    border-bottom: solid 2px #4956a8;
}
.back-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    display: block;
    font-size: 1.1em;
}
.edit-icon {
    position: absolute;
    right: 15px;
    top: 13px;
    color: rgba(148, 143, 181, 1);
}
.custom-switch .MuiSwitch-sizeMedium .MuiSwitch-colorPrimary.Mui-checked {
    color: #fff;
}
.custom-switch .MuiSwitch-sizeMedium .MuiSwitch-track {
    border-radius: 58px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.custom-switch .MuiSwitch-sizeMedium .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background: #f77307;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 58px;
}
.custom-switch .MuiSwitch-sizeMedium .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    opacity: 1;
}
.custom-switch .MuiSwitch-sizeMedium {
    padding: 4px 2px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: normal;
}
.css-oys24r-MuiFormControl-root > label[data-shrink='false'] {
    top: 0px;
}

// <MAT UI css
.css-ujljcm-MuiGrid-root {
    justify-content: flex-start;
}
.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.theme-bg {
    background: rgb(40, 53, 147) !important;
}
.view-investor-block .custom-card .card-header {
    background-color: #f3f4fa;
    color: #303f9f;
}
.steps-block {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
}
.lp-summary-table table {
    border-top: 1px solid rgba(224, 224, 224, 1) !important;
}
.lp-summary-table table tr th:first-child,
.lp-summary-table table tr td:first-child,
.lp-summary-table table tr th:last-child,
.lp-summary-table table tr td:last-child {
    border-left: 1px solid rgba(224, 224, 224, 1) !important;
    border-radius: 0 !important;
}
.lp-summary-table table thead tr th {
    border-right: 1px solid rgb(120 120 120 / 30%) !important;
    border-bottom: 1px solid rgb(120 120 120 / 30%) !important;
    font-weight: 700;
    font-size: 14px;
}
.lp-summary-table table tbody tr td {
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    font-weight: 700;
    font-size: 14px;
    color: #616161;
}
.lp-summary-table table tbody tr.total-row td {
    border-right: 1px solid rgb(120 120 120 / 30%) !important;
}
.cs-close svg {
    color: #cfcfcf !important;
}
.error-message {
    color: #721c24;
    padding: 10px;
}

.custom-menu-block {
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
    padding: 1rem;
    position: relative;
}
.custom-menu-block .side-bar {
    width: 100%;
    height: auto;
    position: sticky;
    top: 69px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    z-index: 1;
    background-color: #fff !important;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.custom-menu-block .side-bar ul {
    padding: 0;
    margin: 0;
}

.custom-menu-block .side-bar li {
    list-style: none;
    display: flex;
}

.custom-menu-block .side-bar li button,
.custom-menu-block .side-bar li a {
    text-decoration: none;
    display: block;
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 24px;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    padding: 12px 16px;
    color: #9e9e9e;
}

.custom-menu-block .side-bar li button.active:after,
.custom-menu-block .side-bar li a.active:after {
    content: '';
    border-bottom: solid 2px rgb(40, 53, 147);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}
.custom-menu-block .side-bar li button:hover,
.custom-menu-block .side-bar li a:hover {
    color: rgb(40, 53, 147);
}
.custom-menu-block .side-bar-tabs .nav-link.active {
    color: rgb(40, 53, 147);
}
.custom-menu-block .custom-card {
    box-shadow: none;
}
.custom-menu-block .main-content > .custom-card {
    box-shadow: none;
}
.close-pos-right {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}
.mxh-scroll {
    max-height: calc(100vh - 459px);
    overflow-y: auto;
    padding: 0 15px 0 0;
}
.mxh-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}
.mxh-scroll::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
}
.mxh-scroll::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}
.font-12 {
    font-size: 12px !important;
}
.font-13 {
    font-size: 13px !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.font-18 {
    font-size: 18px !important;
}
.h-50 {
    height: 50px !important;
}
.circle-box {
    width: 45px;
    height: 45px;
    background: #e0ede7;
    border-radius: 50%;
    position: relative;
    min-width: 45px;
    overflow: hidden;
    border: solid 1px #ccc;
}
.circle-box img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 86%;
}
.text-gray {
    color: #bbb9b9 !important;
}
.w-40 {
    width: 40% !important;
}
.gray-box {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 10px;
    padding: 15px;
    width: 200px;
}
.gray-box .icon {
    min-height: 45px;
    margin-bottom: 15px;
}
.gray-box .icon img {
    max-height: 45px;
    display: inline-block;
}
.icon-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #616161;
}
.or-cbox {
    width: 35px;
    height: 35px;
    min-width: 35px;
    line-height: 35px;
}

.deal-img-box .circle-box {
    width: 42px;
    height: 42px;
    min-width: 42px;
    line-height: 42px;
    background: #fff;
    border: solid 1px #cfcfcf;
    position: relative;
}
.deal-head-flex .circle-box img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.arrow-btn {
    background: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
}
.arrow-btn img {
    width: 23px;
    height: 23px;
}
.box-shadow-none {
    box-shadow: none !important;
}

.v-label.fw-600 {
    font-weight: 500 !important;
}
.checklist-master-card.custom-card .card-header {
    padding: 7px 10px 3px;
}
.checklist-master-card.custom-card .card-body {
    padding: 11px 10px 0;
}
.dhf-right {
    width: calc(100% - 80px);
    padding-left: 20px;
}
.dhf-right .gray-box {
    min-height: inherit;
    padding: 8px;
    width: 50px;
}
.dhf-right .icon {
    color: #616161 !important;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}
.dhf-right .icon a,
.dhf-right .icon button {
    color: #f77307 !important;
    padding: 0;
}
.dhf-right .icon img {
    max-width: 20px;
    max-height: 20px;
}
.dh-tabs {
    margin: 0;
}
.deal-head-flex .circle-box {
    width: 80px;
    height: 80px;
    min-width: 80px;
    overflow: hidden;
}
.document-list {
    padding: 0 0 0 20px;
}
.document-list li {
    padding: 15px 0;
}
.document-list li + li {
    border-top: 1px solid #eee9fc;
}

.demo-server-block {
    width: 100%;
    padding: 5px;
    background-color: #0000ff;
    text-align: center;
    font-size: 12px;
    color: #fff;
}
.main-header {
    flex-wrap: wrap;
}
.w-60 {
    width: 60%;
}
.dhf-investor-name {
    color: #000;
    font-size: 1.1rem;
    font-weight: 600;
}
.dhf-company-name {
    // color: #bbb9b9;
    color: #bbb9b9;
    font-weight: 600;
    // font-size: 14px;
    font-size: 1.1rem;
}
.deal-status-value {
    line-height: 30px;
    text-align: center;
}
.amount-crore-text {
    color: #6e6e6e;
    font-size: 13px;
    margin-top: 6px;
}
.mxw-196 .MuiSelect-select {
    max-width: 100%;
}
.add-border {
    border: 1px solid rgba(224, 224, 224, 1);
}
.role-permission-table {
    overflow-x: auto;
}
.role-permission-table tbody tr td:first-child {
    font-weight: 600;
    color: #000;
}
.role-permission-table th label,
.role-permission-table td label {
    margin-right: 0;
    margin-left: 0;
}
.role-permission-table thead th h6 {
    color: #fff;
}
.role-permission-table thead th svg {
    fill: #fff !important;
}
// .table-sticky table thead {
//     position: sticky;
//     top: 100px;
// }
// .table-sticky {
//     overflow-x: inherit;
// }

.info-box.info-left .info-pos {
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.15);
    left: inherit;
    right: 35px;
    width: 520px;
    text-align: left;
    font-size: 12px;
    background: #fff;
    z-index: 10000;
    top: -8px;
    transform: inherit;
    font-weight: 400;
}
.info-box.info-left .info-pos strong {
    font-weight: 900;
}
.info-box.info-left .info-pos > div {
    padding: 4px 0;
}
.info-box.info-left .info-pos:before {
    display: none;
}
.info-box.info-left .info-pos:after {
    content: '';
    position: absolute;
    right: -10px;
    top: 15px;
    border-left: solid 10px #fff;
    border-top: solid 10px transparent;
    border-bottom: solid 10px transparent;
}
.info-box.info-right .info-pos {
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.15);
    left: 50px;
    width: 520px;
    text-align: left;
    font-size: 12px;
    background: #fff;
    z-index: 10000;
    top: -8px;
    transform: inherit;
    font-weight: 400;
}
.info-box.info-right .info-pos strong {
    font-weight: 900;
}
.info-box.info-right .info-pos > div {
    padding: 4px 0;
}
.info-box.info-right .info-pos:before {
    display: none;
}
.info-box.info-right .info-pos:after {
    content: '';
    position: absolute;
    left: -10px;
    top: 15px;
    border-right: solid 10px #fff;
    border-top: solid 10px transparent;
    border-bottom: solid 10px transparent;
}
.form-control {
    padding: 10px 14px;
    border-radius: 4px;
    background: #fafafa;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4375em;
    font-family: 'Inter', sans-serif;
    color: #616161;
    box-sizing: border-box;
    border-color: #a0a8da;
    border-width: 0.5px;
}
.w-70 {
    width: 80px;
    min-width: 80px;
}

.vp-investment-dtl-table {
    overflow-x: auto;
}
.custom-hr {
    border-top: solid 1px #e0e0e0;
    padding-top: 12px;
    margin-top: 12px;
}
.main-checkbox h6 {
    font-size: 16px;
    font-weight: 700;
}
.ps-30 {
    padding-left: 30px;
}

.ud-box {
    padding: 10px 10px;
}
.ud-header-row .col {
    padding: 10px 10px;
}
.list-view .upload-document-row .col {
    flex: auto;
    padding: 10px 10px;
}
.list-view .upload-document-row .col .ud-box {
    padding: 0;
}
.upload-document-row .col:first-child {
    width: 25%;
    text-align: left;
}
.upload-document-row .col:nth-child(2) {
    width: 15%;
    text-align: left;
}
.upload-document-row .col:nth-child(3) {
    width: 20%;
    text-align: left;
}
.upload-document-row .col:nth-child(4) {
    width: 15%;
    text-align: left;
}
.upload-document-row .col:nth-child(5) {
    width: 15%;
    text-align: left;
}
.upload-document-row .col:last-child {
    width: 10%;
    text-align: center;
}
.upload-document-list .upload-document-row:nth-of-type(even) {
    background-color: #f3f4fa;
}
.ud-box .xs-title {
    text-decoration: none;
    color: rgb(97, 97, 97);
    font-weight: 400;
    font-size: inherit;
}
.ud-box .xs-title .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 50px);
}
.ud-box .xs-title .title-line-break {
    max-height: 38px;
    overflow: hidden;
}
.upload-document-row {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    align-items: center;
}
.remove-border-collapse {
    border-collapse: inherit !important;
    border-top: 1px solid rgb(224, 224, 224) !important;
}

.view-type {
    display: flex;
    align-items: center;
}
.transparent-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #616161;
}
.upload-document-list.list-view {
    border: 1px solid rgb(224, 224, 224);
    overflow-x: auto;
    max-height: calc(100vh - 350px);
}
.upload-document-list.grid-view {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.upload-document-list.grid-view .upload-document-row {
    background-color: #f3f4fa;
    color: #303f9f;
    width: calc(20% - 20px);
    margin: 0 10px;
    padding: 5px;
    border-radius: 8px;
    border-top: none;
    margin-bottom: 20px;
}
.text-black {
    color: #333 !important;
}
.ud-header-row {
    color: white;
    background-color: #0000ff;
    position: sticky;
    top: 0;
}

// Skleton Loader Animaton

.skeleton-table .tg {
    border-collapse: collapse;
    border-spacing: 0;
}

.skeleton-table.custom-table table thead tr th {
    font-size: 14px;
    padding: 13px 16px 13px 16px !important;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    word-break: normal;
}

.skeleton-table.custom-table table tbody tr td {
    font-size: 14px;
    font-weight: normal;
    padding: 10px 16px;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    word-break: normal;
}

.line {
    width: 100%;
    height: 25px;
    margin-top: 0;
    border-radius: 7px;
    background-image: linear-gradient(90deg, #f4f4f4 0px, rgba(229, 229, 229, 0.8) 40px, #f4f4f4 80px);
    background-size: 600px;
    animation: shine-lines 2s infinite ease-out;
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }
    40%,
    100% {
        background-position: 140px;
    }
}

.file-upload-menu {
    position: relative;
}
.file-upload-menu input {
    position: absolute;
    left: -90px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
}
.mxw450 .cs-modal {
    max-width: 450px;
}
.ud-link {
    color: rgb(97, 97, 97);
    text-decoration: none;
}

.upload-document-block .cs-border {
    border-bottom: solid 1px rgb(224, 224, 224);
    margin: 15px 0;
}
.ud-icon {
    max-width: 30px;
}
.cd-breadcrumb {
    font-size: 15px !important;
}
.cd-breadcrumb button.ud-link {
    background: transparent;
    border: none;
    padding: 0;
}
.cd-breadcrumb a:first-child svg,
.cd-breadcrumb button:first-child svg {
    display: none;
}
.cd-breadcrumb svg {
    vertical-align: -7px;
}
.cd-breadcrumb .dot-link {
    padding-left: 15px;
    padding-right: 5px;
}
.mxw550 .cs-modal {
    max-width: 550px;
}

//
.file-upload-box {
    border: 2px dashed rgba(0, 0, 0, 0.23);
    background-color: #f0f2f7;
    border-radius: 4px;
    min-height: 150px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 15px;
}
.file-upload-box .file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.file-link {
    cursor: pointer;
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px;
}
.file-upload-box .file-link:hover {
    text-decoration: none;
}
.file-atc-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.file-image {
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
}
.file-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}
.file-detail {
    flex: 1;
    width: calc(100% - 210px);
}
.file-detail h6 {
    word-break: break-all;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 0;
    vertical-align: top;
}
.file-detail p {
    font-size: 12px;
    color: #b3b3b3;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 8px;
    margin-top: 0;
    display: block;
    height: auto;
}
.file-actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.file-action-btn {
    font-size: 12px;
    color: #283593;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-right: 15px;
    cursor: pointer;
}
.file-action-btn:hover {
    color: #3d546f;
    text-decoration: underline;
}
.file-atc-box:last-child {
    margin-bottom: 0;
}
.upload-doc-title svg {
    vertical-align: -6px;
}
.font-16 {
    font-size: 16px !important;
}
.no-search-results {
    padding: 40px 15px 20px;
    text-align: center;
}
.upload-btn svg {
    vertical-align: middle;
}
.ml-auto {
    margin-left: auto;
}
.grid-view .no-record-col {
    padding: 0 10px;
}
.grid-view .no-record-box {
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.inv-left {
    max-width: calc(100% - 300px);
    flex-basis: calc(100% - 300px);
}
.inv-right {
    max-width: 300px;
    flex-basis: 300px;
}
.custom-box {
    background-color: #ffffff;
    color: #616161;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    background-image: none;
    border-radius: 4px;
    overflow: hidden;
    border: none;
    border-color: #90caf975;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 16px 20px;
    position: relative;
}
.ud-box2 {
    /* Add other styling properties as needed */
    cursor: pointer;
}
.investor-onboarding-table .add-border {
    border-bottom: none !important;
}
.selected-item {
    background-color: #90caf975 !important;
}
.blue-text {
    color: #0000ff !important;
}
.error {
    margin-top: 0;
    color: #ef5350;
    font-size: 0.77rem;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 1.66;
    margin-left: 33px;
}
.cursor-pointer {
    cursor: pointer !important;
}
.action-btn {
    background-color: #fff;
    border: 1px solid rgb(40, 53, 147);
    height: 42px;
    color: rgb(40, 53, 147);
    &:hover {
        background-color: #fff;
    }
}

.deal-table tr:nth-of-type(odd) td {
    background-color: #f3f4fa;
}
.deal-table tr:nth-of-type(even) td {
    background-color: #fff;
}
.stickyTableHead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
}

.document-list-table table tr:first-child td {
    border-top: 1px solid rgba(224, 224, 224, 1);
}
.document-list-table table tr td:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
}
.document-list-table table tr td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-left: 1px solid rgba(224, 224, 224, 1);
    padding: 8px 12px;
}
.pre-wrap {
    white-space: pre-wrap;
}

div.stepper.custom-box {
    div.MuiStepper-root {
        div.MuiStepConnector-root {
            max-width: 18rem;
        }
    }
}

.mis-inputs-gmv {
    position: relative;
    padding-right: 6%;
    .percentage-display {
        position: absolute;
        top: 24%;
        right: 0;
        width: fit-content;
        min-width: 20%;
        max-width: 30%;
        padding: 0.5rem;
        height: 75%;
        border-radius: 3px;
        background-color: #283593;
        color: white;
        display: grid;
        place-items: center;
    }
}
.VictoryContainer {
    svg > g > g > text > tspan {
        font-family: 'Inter', sans-serif !important;
        font-size: 0.7rem !important;
    }
    svg > g > text > tspan {
        font-family: 'Inter', sans-serif !important;
        font-size: 0.5rem !important;
    }
}

div.MuiTableContainer-root::-webkit-scrollbar ,div.custom-table::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    height: 6px;
    display: none;
}

div.MuiTableContainer-root::-webkit-scrollbar-thumb div.custom-table::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 25px;
}

div.MuiTableContainer-root::-webkit-scrollbar-thumb:hover div.custom-table::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


.value-link {
    color: #616161;
}

.mxh-scroll-checklist {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 0 15px;
}
.mxh-scroll-checklist::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}
.mxh-scroll-checklist::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
}
.mxh-scroll-checklist::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}

.fullscreen-view{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999; /* Ensure the element is on top of other content */
        /* Additional styles as needed */
    
}

td, th{
    padding: 10px;
}