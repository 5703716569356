.button-79 {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background: #283593;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 0.7rem 1.25rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  /* transform: translateZ(0) scale(1); */
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-79:disabled {
  color: #787878;
  cursor: auto;
}

.button-79:not(:disabled):hover {
  transform: scale(1.05);
}

.button-79:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.button-79:focus {
  outline: 0 solid transparent;
}

.button-79:focus:before {
  border-width: .125rem;
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
  -webkit-user-select: none;
}

.button-79:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-79:not(:disabled):active {
  transform: translateY(.125rem);
}


.modal-confirm {		
	color: #636363;
	width: 550px;
}
.modal-confirm .modal-content {
	padding: 32px;
	border-radius: 5px;
	border: none;        
}
.modal-confirm .modal-header {
	/* padding: 0 15px; */
	border-bottom: none;   
	position: relative;
}
.modal-confirm h4 {
	display: inline-block;
	font-size: 26px;
}
.modal-confirm .close {
	position: absolute;
	top: -5px;
	right: -5px;
}
.modal-confirm .modal-body {
	color: #999;
  margin-bottom: 30px;
  margin-top: 20px;
}
.modal-confirm .modal-footer {
	/* background: #ecf0f1; */
	border-color: #e6eaec;
	text-align: right;
	/* margin: 0 -20px -20px; */
	border-radius: 0 0 5px 5px;
}	
.modal-confirm .btn {
	color: #fff;
	border-radius: 4px;
	transition: all 0.4s;
	border: none;
	padding: 8px 20px;
	outline: none !important;
}	
.modal-confirm .btn-info {
	background: #b0c1c6;
}
.modal-confirm .btn-info:hover, .modal-confirm .btn-info:focus {
	background: #92a9af;
}
.modal-confirm .modal-footer .btn + .btn {
	margin-left: 10px;
}
.trigger-btn {
	display: inline-block;
	margin: 100px auto;
}

div.sticky {
  /* position: -webkit-sticky; */
  position: sticky;
  top: 0;
  background-color: yellow;
  padding: 50px;
  font-size: 20px;
}

/* For Top Alignment
.deal-flow-item {
  padding: 0 10px 20px;
  position: relative;
  text-align: center;
}
.deal-flow-item:before {
  content: '';
  position: absolute;
  left: 2%;
  bottom: 9px;
  border-top: 2px dashed #948fb5;
  width: 49%;
}
.deal-flow-item:after {
  content: '';
  position: absolute;
  right: 0%;
  bottom: 9px;
  border-top: 2px dashed #948fb5;
  width: 50%;
}
.deal-flow-item .circle-dot:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 4px;
  background: #948fb5;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: -6px;
  z-index: 1;
}
.deal-flow-item:first-child:before,
.deal-flow-item:last-child:after {
  display: none;
}
.deal-flow-item:last-child {
  margin-bottom: 0;
}

.deal-flow-item.completed:before {
  border-color: #188351;
}
.deal-flow-item.completed:after {
  border-color: #188351;
}
.deal-flow-item.completed .circle-dot:after {
  background: #188351;
} */


.deal-flow-item {
  padding: 0 30px 0;
  position: relative;
  text-align: center;
}
.deal-flow-item .v-value {
  padding-bottom: 3px;
  word-break: normal !important;
  height: 37px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.deal-flow-item .v-label {
  padding-top: 0;
  margin-top: 21px;
  height: 25px;
  line-height: normal;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.deal-flow-item:before {
  content: '';
  position: absolute;
  left: 2%;
  bottom: calc(34% + 9px);
  border-top: 2px dashed #948fb5;
  width: 49%;
}
.deal-flow-item:after {
  content: '';
  position: absolute;
  right: 0%;
  bottom: calc(34% + 9px);
  border-top: 2px dashed #948fb5;
  width: 50%;
}
.deal-flow-item .circle-dot:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: calc(33% + 5px);
  background: #948fb5;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: -6px;
  z-index: 1;
}
.deal-flow-item:first-child:before,
.deal-flow-item:last-child:after {
  display: none;
}
.deal-flow-item:last-child {
  margin-bottom: 0;
}

.deal-flow-item.completed:before {
  border-color: #188351;
}
.deal-flow-item.completed:after {
  border-color: #188351;
}
.deal-flow-item.completed .circle-dot:after {
  background: #188351;
}
.icon-size {
  width: 22px;
  height: 22px;
}
.wd-19 {
  width: 19px;
  height: 19px;
}
.font-10 {
  font-size: 10px !important;
}

/* Responisve css */
@media (max-width:1680px) {
  .deal-flow-item {
    padding: 0 20px 0;
  }
}
@media (max-width:1480px) {
  .deal-flow-item {
    padding: 0 10px 0;
  }
}
@media (max-width:991px) {
  .deal-flow-item {
    padding: 0 5px 0;
  }
  .deal-flow-item .v-value {
    padding-bottom: 17px;
    line-height: 15px;
  }
}
.crossdisable button{
display: none !important;
}
.crossdisable.email_input > div.MuiFormControl-root > div.MuiInputBase-root {
  padding: 4px;
}
#email-input-label {
  top: -5px;
}
#email-input-label.Mui-focused {
  top: 0px;
}
.chrome-picker  {
  width: 100% !important;
}
.color-picker {
  vertical-align: top;
  height: 42px !important;
  position: absolute !important;
  left: 0;
  top: 0;
  border: none !important;
  background: transparent !important;
}
.color-picker span {
  border: solid 1px #ebebeb;
}
.custom-colorpicker {
  position: relative;
}
.custom-colorpicker>.form-control {
  padding-left: 70px;
  border: solid 1px #a0a8da;
  background: #fff;
  height: 42px;
}
.custom-colorpicker .css-cnfek5-Popover {
  z-index: 9999;
}
/* In your CSS file or style block */
.file-link {
  color: darkgray; /* or any other gray color you prefer */
  text-decoration: none; /* remove underlines if necessary */
}

/* .file-link:hover { */
  /* Optional: Change color on hover */
  /* color: darkgray; */
/* } */

.button-80 {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background: #a42002;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 0.7rem 1.25rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  /* transform: translateZ(0) scale(1); */
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.mnh-100vh {
  min-height: calc(100vh - 167px);
}
.bar-graph > .VictoryContainer{
  padding-left: 1rem;
}
.bar-graph > .VictoryContainer > svg{
  overflow: visible;
  font-family: inherit,sans-serif !important;
}

.cc-new-theme.custom-card .v-label {
  font-size: 14px;
  margin-bottom: 4px;
}
.cc-new-theme.custom-card .v-value {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
}

.cc-new-theme.custom-card .v-value a {
  color: #303f9f !important;
}